<template>

  <div class="regin">

    <div class="backHome ">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      招聘信息
    </div>
    <div class="line">
      <span class="lable">业务类型<i class="must_input">*</i>:</span>
      <span style="margin-left: 80px">
        <select title="业务类型" v-model="type" @change="type_select()">
          <option value="2" selected>招聘</option>
          <option value="1">求职</option>
        </select>
      </span>
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">公司名称<i class="must_input">*</i>:</span>
      <input type="text" name="" v-model="job_unit" />
    </div>
    <div class="line">
      <span class="lable" v-if="type=='2'">发布人<i class="must_input">*</i>:</span>
      <span class="lable" v-else>求职人<i class="must_input">*</i>:</span>
      <input type="text" name="" v-model="hr_name" placeholder="请输入您希望显示在页面的名字" />
    </div>
    <div class="line">
      <span class="lable">联系电话<i class="must_input">*</i>:</span>
      <input type="text" oninput="handleInput" v-model="tel" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">最低薪资<i class="must_input">*</i>:</span>
      <input type="number" oninput="handleInput" v-model="monthly_pay1" @blur="monthly_pay1" placeholder="" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">最高薪资<i class="must_input">*</i>:</span>
      <input type="number" oninput="handleInput" v-model="monthly_pay2" @blur="monthly_pay2" placeholder="" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">招聘人数<i class="must_input">*</i>:</span>
      <input class="text" type="number" oninput="handleInput" name="" v-model="head_count" id="head_count"
        ref="head_count" placeholder="请输入招聘人数" />
    </div>
    <div class="line">
      <span class="lable">标题<i class="must_input">*</i>:</span>
      <input class="text" type="text" name="" v-model="job_title" placeholder="标题" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">福利待遇<i class="must_input">*</i>:</span>
      <input class="text" type="text" name="" v-model="welfare_treatment" placeholder="请用,分割福利待遇" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">学历要求<i class="must_input">*</i>:</span>
      <span style="margin-left: 80px">
        <select title="学历要求" v-model="education">
          <option value="1">不限制</option>
          <option value="2">初中及以上</option>
          <option value="3">高中及以上</option>
          <option value="4">大专及以上</option>
          <option value="5">大学及以上</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">工作城市<i class="must_input">*</i>:</span>
      <input type="text" placeholder="请选择" name="" v-model="work_city" @click="chooseHouseArea=true;" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">工作地址<i class="must_input">*</i>:</span>
      <input type="text" name="" v-model="work_place" id="work_place" ref="work_place" placeholder="地址" />
    </div>
    <div class="line" v-if="type=='2'">
      <span class="lable">工作年限<i class="must_input">*</i>:</span>
      <span style="margin-left: 80px">
        <select title="工作年限" v-model="work_years">
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
          <option value="9">9</option>
          <option value="10">10</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable">范围<i class="must_input">*</i>:</span>
      <span style="margin-left: 80px">
        <select title="全职兼职" v-model="jobs_range">
          <option value="1">全职</option>
          <option value="2">兼职</option>
        </select>
      </span>
    </div>
    <div class="line">
      <span class="lable" v-if="type=='2'">职位描述</span>
      <span class="lable" v-else>个人介绍</span>
    </div>
    <div class="line" style="height: 34.4vw;">
      <textarea style="width: 100%; border: 0;" rows="8" v-model="job_desc" id="job_desc" ref="job_desc" autosize
        type="textarea" placeholder="请描述一下您的需求,最多200汉字" />
    </div>

    <div class="bottomBtn" @click="save()">保存</div>

    <!--    <div v-if="type == 'edit'" class="bottomBtn" style="margin-bottom: 1px" @click="not_sell()">
      房产下架
    </div>
    <div class="line" v-if="type == 'edit'">
      <span class="lable">下架原因</span>
      <span style="margin-left: 80px">
        <select title="室">
          <option value="0">我不想卖了</option>
          <option value="1">其它方式卖了</option>
          <option value="2">已经卖出</option>
        </select>
      </span>
    </div> -->

    <van-popup v-model="chooseHouseArea" round position="bottom">
      <van-cascader v-model="chooseHouseArea" title="请选择工作城市,点×可以完成选择" :options="quxianJson"
        @close="chooseHouseArea = false;" @change="chooseHouseAreaChange" @finish="chooseHouseArea = false;" />
    </van-popup>
  </div>
</template>

<script>
  import {
    RadioGroup,
    Radio,
    DatetimePicker,
    Popup,
    Cascader,
    Toast
  } from "vant";
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import axios from "axios";
  import qs from "qs";
  import area from "@/utils/4-area.json";

  export default {
    name: "regin",
    props: {},
    data() {
      return {
        chooseHouseArea: false,
        chooseRegion: false, //本次城市选择具体选中的是哪个城市
        choosedRegion: '', //具体选择的城市地址
        quxianJson: [], ////获取所有4级城市的数据
        jobs_range_arr: ['', '全职', '兼职'],
        job_id: '', //招聘的id
        type: '', //1求职  2招聘
        job_title: '',
        monthly_pay1: '',
        monthly_pay2: '',
        welfare_treatment: '', //职务标签
        jobs_range: '1',
        head_count: '',
        job_desc: '',
        education: '',
        work_years: '',
        work_city: '',
        work_place: '',
        tel: '',
        edit_time: '',
        num_applicants: '',
        num_hits: '',
        job_unit: '',
        hr_name: '',
        sort: '99',
        priceRegex: /^(([1-9]\d*)(\.\d{1,4})?)$|(0\.\d{1,4})$/,
      };
    },
    components: {},
    mounted() {
      //判断一下，cookie如果丢失了，那么就重新登录
      if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
        //如果获取用户信息失败那就直接跳到登录
        this.$router.push("login");
        return;
      }
      this.quxianJson = this.parseArea();
      this.userCode = Cookies.get("kh");
      let url = location.href;
      let jobid = decodeURI(getQueryVariable(url, "id")); //id
      let job_type = decodeURI(getQueryVariable(url, "job_type")); //本次的类型  1求职 2招聘
      this.job_id = jobid;
      this.type = job_type;
      // console.log(jobid);
      if (
        jobid == "" ||
        jobid == null ||
        jobid == "null" ||
        jobid == "undefined" ||
        jobid == undefined
      ) {
        this.operation_type = "add";
      } else {
        //不传值就不需要加载
        //读出配置信息
        this.operation_type = "edit"; //修改状态下,我不想卖按钮可见
        this.getJobDetails(jobid);
        document.addEventListener('WeixinJSBridgeReady', function onBridgeReady() {
          // 通过下面这个API隐藏右上角按钮  这样不允许分享的页面不能分享
          WeixinJSBridge.call('hideOptionMenu');
        });
      }
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },
      // priceBlur() {
      //   if (!this.price || !this.priceRegex.test(this.price) || this.price <= 0) {
      //     Toast('总价输入错误');
      //   }
      // },
      parseArea() {
        //console.log(area);
        //console.log(area instanceof Array);
        let areaJs = JSON.parse(JSON.stringify(area));
        //console.log(areaJs instanceof Array);
        let result = areaJs.map(item => {
          return {
            text: item.pro_name,
            value: item.pro_code,
            children: item.pro_cities.map(e => {
              return {
                text: e.city_name,
                value: e.city_code,
                children: e.city_areas.map(val => {
                  return {
                    text: val.area_name,
                    value: val.area_code,
                    children: val.area_streets.map(t => {
                      return {
                        text: t.street_name,
                        value: t.street_code
                      }
                    })
                  }
                })
              }
            })
          }
        })
        return result;
      },
      chooseHouseAreaChange({
        selectedOptions
      }) {
        let areaName = selectedOptions.map((option) => option.text).join('/');
        let areaCode = selectedOptions[selectedOptions.length - 1].value;
        console.log(areaName);
        console.log(areaCode);
        this.work_city = areaName;
      },
      handleInput(e) {
        //限制只能输入数字
        this.val = e.target.value.replace(/[^\d]/g, '');
      },
      back(where) {
        //顶部后退和返回按钮
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prev") {
          this.$router.back();
        }
      },
      onFinish({
        selectedOptions
      }) {
        this.nationActive = false;
        this.nationText = selectedOptions.map((option) => option.text).join("/");
      },
      type_select() {
        //求职和招聘,选择后加载对应的项目

      },
      async save() {
        //保存本次修改的招聘信息
        let url = location.href;
        let operation_type = decodeURI(getQueryVariable(url, "operation_type"));

        // 触发
        this.$toast.loading("加载中...");


        // // 调用接口之前禁用按钮，防止多次点击调用接口
        // this.disabledFlag = true
        // // 或者打开loading等待
        // this.loading.show()
        // // 调用接口进行添加填报数据
        // api.save({
        //   id: this.id
        // }).then(res => {
        //   // 接口返回后
        //   this.disabledFlag = false
        //   this.loading.hide()
        //   this.$message({
        //     type: 'success',
        //     message: "保存成功！"
        //   })
        // }).catch(() => {
        //   this.disabledFlag = false
        //   this.loading.hide()
        // })


        //判断必填项目

        if (this.type == '2') {
          //1求职  2招聘
          if (!this.job_unit) {
            Toast('请输入公司名称');
            return;
          }

          if (!this.monthly_pay1 || !this.priceRegex.test(this.monthly_pay1) || this.monthly_pay1 <= 0) {
            Toast('起始薪资输入错误');
            return;
          }
          if (!this.monthly_pay2 || !this.priceRegex.test(this.monthly_pay2) || this.monthly_pay2 <= 0) {
            Toast('结束薪资输入错误');
            return;
          }

          if (!this.head_count || !this.priceRegex.test(this.head_count) || this.head_count <= 0) {
            Toast('招聘人数输入错误');
            return;
          }
          if (!this.welfare_treatment) {
            Toast('请输入福利待遇');
            return;
          }

          if (!this.education) {
            Toast('请选择学历要求');
            return;
          }

          if (!this.work_place) {
            Toast('请输入工作地点');
            return;
          }

          if (!this.work_years) {
            Toast('请选择工作年限');
            return;
          }

        }

        if (!this.hr_name) {
          Toast('请输入发布人名称');
          return;
        }

        if (!this.tel) {
          Toast('请输入联系电话');
          return;
        }


        if (!this.job_title) {
          Toast('请输入标题');
          return;
        }


        if (!this.work_city) {
          Toast('请选择工作城市');
          return;
        }


        if (!this.job_desc) {
          Toast('请输入描述');
          return;
        }



        let act = "updateJobsDetails";
        if (operation_type == "add") {
          //如果是新增,走新增接口
          act = "addJobsDetails";
        }
        var that = this;
        var uri = this.$global.domain + "/tools/post_job.ashx";
        var param = {
          act: act,
          user: Cookies.get("kh"),
          id: that.job_id, //房屋id
          // jobid:that.jobid ,
          type: that.type,
          job_title: that.job_title,
          monthly_pay1: that.monthly_pay1,
          monthly_pay2: that.monthly_pay2,
          welfare_treatment: that.welfare_treatment,
          jobs_range: that.jobs_range,
          head_count: that.head_count,
          job_desc: that.job_desc,
          education: that.education,
          work_years: that.work_years,
          work_city: that.work_city,
          work_place: that.work_place,
          tel: that.tel,
          edit_time: that.edit_time,
          num_applicants: that.num_applicants,
          num_hits: that.num_hits,
          job_unit: that.job_unit,
          hr_name: that.hr_name,
          sort: that.sort,
          sitenum: "18777777777",
          token: Cookies.get("token"),
          code: Cookies.get("code"),
        };
        let result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          Toast(result.msg);
          //延时跳转 让顾客看清提示
          setTimeout(() => {
            //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
            this.$router.push("my_job");
            //延迟时间：3秒
          }, 500);
        } else {
          // 关闭
          that.$toast.clear();
          Toast(result.msg);
        }
      },
      async getJobDetails(id) {
        //获取自己创建的招聘信息

        var that = this;
        let uri = this.$global.domain + "/tools/post_job.ashx";
        let param = {
          act: "getJobsDetails",
          id: id, //本次查询的招聘编号
          user: Cookies.get("kh"),
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        let result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          let data = result.data;
          that.job_id = data[0][0];
          that.type = data[0][1];
          that.job_title = data[0][2];
          that.monthly_pay1 = data[0][3];
          that.monthly_pay2 = data[0][4];
          that.welfare_treatment = data[0][5]; //职务标签
          that.jobs_range = data[0][6];
          that.head_count = data[0][7];
          that.job_desc = data[0][8];
          that.education = data[0][9];
          that.work_years = data[0][10];
          that.work_city = data[0][11];
          that.work_place = data[0][12];
          that.tel = data[0][13];
          that.edit_time = data[0][14];
          that.num_applicants = data[0][15];
          that.num_hits = data[0][16];
          that.job_unit = data[0][17];
          that.hr_name = data[0][18];
          that.sort = data[0][23];

        } else {
          //this.$router.push("login");
        }
      },

    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .must_input {
    color: red;
  }

  .regin {
    .title {
      height: 68px;
      line-height: 68px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      font-size: 18px;
      font-weight: bold;
      text-align: left;
      color: #3d3d3d;
    }

    .line {
      height: 54px;
      line-height: 54px;
      width: 348px;
      position: relative;
      margin: 0 auto;
      margin-bottom: 18px;
      font-size: 14px;
      text-align: left;
      color: #6e6e6e;

      // border: 1px red dashed;
      input {
        height: 50px;
        border: 1px #f2f2f2 solid;
        width: 344px;
        color: #464646;
        text-indent: 6rem;
      }

      .superIndent {
        text-indent: 120px;
      }

      .lable {
        text-indent: 12px;
        display: inline-block;
        // width: 76px;
        position: absolute;
      }

      .sexGroup {
        position: relative;
        // border: 1px red solid;
        width: 210px;
        margin-top: 17px;
        margin-left: 70px;
        display: inline-block;
      }

      .suojin {
        margin-left: 16px;
        left: 56px;
        position: relative;
      }

      .greenColor {
        position: relative;
        // border: 1px red solid;
        width: 280px;
        margin-top: 17px;
        margin-left: 30px;
        display: inline-block;

        i {
          color: #62c3ab;
          font-style: initial;
        }
      }
    }
  }

  .bottomBtn {
    position: relative;
    margin: 22px auto 90px auto;
    width: 354px;
    height: 54px;
    line-height: 54px;
    border-radius: 40px;
    background-color: #36c1bc;
    ;
    color: white;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    // position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;
    margin-bottom: 18px;

    span {
      position: absolute;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
